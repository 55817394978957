import React from 'react';
import classNames from 'classnames';
import indexOf from 'lodash/indexOf';
import pull from 'lodash/pull';
import { transformPrice } from '../../utils/formattes';
import circleOk from '../../assets/icons/circle-ok.svg';
import randomAll from '../../assets/icons/random-all-icon.svg';
import closeRed from '../../assets/icons/close-red.svg';
import './ticket.scss';
import { injectIntl } from 'gatsby-plugin-intl';


const Ticket = ({ ticket, handlePickNumber, handleTicketRandom, handleTicketCancel, intl: {formatMessage} }) => {

  const translate = (str) => (formatMessage({id: str}))
  if(!ticket){
    return (
      <div className={'game-ticket-numbers-wrap'}>
        <span className={'placeholder-data'} style={{
          height: 500,
          width: 263
        }}></span>
      </div>
    )
  }
  const isValid = () => {
    let isValid = null;
    try {
      isValid = ticket.mainNumbers.length === ticket.game.regNumCount && ticket.bonusNumbers.length === ticket.game.bonusNumCount;
    } catch (e) {
      isValid = false;
    }
    return isValid;
  };
  let pricePerLine = 0;
  ticket.prices.forEach((res) => {
    if (res.quantity === 1) {
      pricePerLine = res.pricePerLine;
    }
  });

  const handleNumber = (numArr: number[], num: number, bonusNumbers = false) => {
    const maxQuantity = bonusNumbers ? ticket.game.bonusNumCount : ticket.game.regNumCount;
    if (indexOf(numArr, num) >= 0) {
      pull(numArr, num);
    } else if (numArr.length < maxQuantity) {
      numArr.push(num);
    }
  };
  const pickNumber = (num: number, bonusNumbers = false) => {
    const newNumbers = [...(bonusNumbers ? ticket?.bonusNumbers : ticket?.mainNumbers)];
    handleNumber(newNumbers, num, bonusNumbers);
    handlePickNumber(ticket.id, newNumbers, bonusNumbers);
  };
  return (
    <div className={classNames('game-ticket-numbers-wrap', {
      valid: isValid(),
    })}
    >
      <div className="game-ticket-numbers">
        <svg className="edge top">
          <use xlinkHref="#top-edge" />
        </svg>

        <div className="row-title">
          <div className="title main-title">{
            formatMessage({id: `Pick {number} ${ticket?.regNumCount - ticket?.mainNumbers.length === 1 ? 'Number': 'Numbers'}`}, {
              number: ticket?.regNumCount - ticket?.mainNumbers.length
            })
          }</div>
          <div className="price-block">
            <div className="circle-ok">
              <img src={circleOk} alt="" />
            </div>
            <div className="price">
              {transformPrice(pricePerLine, 'forCart')}
            </div>
          </div>
        </div>

        <div className="line-header">
          <div className="row-buttons">
            <div className="random">
              <button onClick={() =>handleTicketRandom(ticket.id)}>
                <img src={randomAll} alt="" />
                <span>{translate("Random")}</span>
              </button>
            </div>
            <div className="clear">
              <button onClick={() => handleTicketCancel(ticket.id)} className={classNames({
                disabled: !ticket.mainNumbers.length && !ticket.bonusNumbers.length
              })}>
                <img src={closeRed} alt="" />
                <span>{translate("Clear")}</span>
              </button>
            </div>
          </div>
        </div>

        <ul className="numbers">
          {ticket.numbers.map((element) => (
            <li
              className={classNames('number', {
                selected: ticket.mainNumbers.includes(element),
                disabled: ticket?.mainNumbers.length === ticket.game.regNumCount
              })}
              onClick={() => pickNumber(element)}
            >
              {element}
            </li>
          ))}
        </ul>
        <svg className="edge bottom">
          <use xlinkHref="#btm-edge" />
        </svg>
      </div>
      {ticket.bonusNumCount && (
      <div className="game-ticket-numbers">
        <svg className="edge top">
          <use xlinkHref="#top-edge" />
        </svg>

        <div className="row-title">
          <div className="title">
            {
              formatMessage({id: `Pick {number} ${ticket?.bonusNumCount - ticket?.bonusNumbers.length === 1 ? 'Number': 'Numbers'}`}, {
                number: ticket?.bonusNumCount - ticket?.bonusNumbers.length
              })
            }</div>
        </div>

        <ul className="numbers">
          {ticket.bonuses.map((element) => (
            <li
              className={classNames('number', {
                selected: ticket.bonusNumbers.includes(element),
                disabled: ticket?.bonusNumbers.length === ticket.game.bonusNumCount
              })}
              onClick={() => pickNumber(element, true)}
            >
              {element}
            </li>
          ))}
        </ul>

        <svg className="edge bottom">
          <use xlinkHref="#btm-edge" />
        </svg>
      </div>
      )}
    </div>
  );
};

export default injectIntl(Ticket);
