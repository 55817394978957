import React, { useState, useEffect } from "react"
import './tickets-mobile.scss';
import classNames from 'classnames';
import randomAllIcon from '../../assets/icons/random-all-icon.svg';
import edit from '../../assets/icons/edit.png';
import { newTicket } from "../../utils/ticketsUtils"
import indexOf from "lodash/indexOf"
import pull from "lodash/pull"
import {injectIntl} from 'gatsby-plugin-intl';

const TicketComponentMobile = ({ tickets, handleTicketCancel, currentGame, addNewTicket, translate, intl: {formatMessage} }) => {
  const [showMobileModal, setShowMobileModal] = useState(false);
  const [bonusView, setBonusView] = useState(false);
  const [ticket, setTicket] = useState(null);

  const handleShowModal = () => {
    setShowMobileModal(true)
    setTicket(newTicket(currentGame))
  }

  console.log(ticket);
  const handleProceed = () => {
    if(!bonusView && ticket.bonusNumCount) setBonusView(true);
    if(bonusView || !ticket.bonusNumCount){
      setShowMobileModal(false);
      addNewTicket(ticket);
      setTicket(null);
      setBonusView(false);
    }
  };
  const handleNumber = (numArr: number[], num: number) => {
    const maxQuantity = bonusView ? ticket.game.bonusNumCount : ticket.game.regNumCount;
    if (indexOf(numArr, num) >= 0) {
      pull(numArr, num);
    } else if (numArr.length < maxQuantity) {
      numArr.push(num);
    }
  };

  const handleBack = () => {
    setBonusView(false);
  }
  const handleEditTicket = (ticket) => {
    setTicket(ticket);
    setBonusView(false);
    setShowMobileModal(true);
  };

  const handleClose = () => {
    setTicket(null);
    setBonusView(false);
    setShowMobileModal(false);
  };
  const handleRandomCard = () => {
    const newRandomTicket = newTicket(currentGame, true);
    addNewTicket(newRandomTicket);
  }
  const handleMobilePicked = (num) => {
    const newNumbers = [...(bonusView ? ticket?.bonusNumbers : ticket?.mainNumbers)];
    handleNumber(newNumbers, num);
    setTicket({
      ...ticket,
      mainNumbers: bonusView ? ticket.mainNumbers : newNumbers,
      bonusNumbers: bonusView ? newNumbers : ticket.bonusNumbers,
    })
  }
  return (
    <div className="tickets-mobile-wrapper">
      <div className="tickets saved-tickets">
        <ul>
          {tickets?.map((element, index) => (
            <li className="ticket">
              <div className="top">
                <div className="title">
                  {translate('Ticket')}
                  {' '}
                  {index + 1}
                </div>
                <div className="actions">
                  <button className="edit" onClick={() => handleEditTicket(element)}>
                    <img src={edit} alt="" />  {translate('Edit')}
                </button>
                  <button className="remove" onClick={() => handleTicketCancel(element.id)}>×</button>
                </div>
              </div>
              <hr />
              <div className="picked-numbers numbers">
                <ul>
                  {element.mainNumbers.map((element) => (
                    <li className="number selected">
                  {element}
                </li>
                  ))}

                  {element.bonusNumbers.map((element) => (
                    <li className="number selected bonus">
                  {element}
                  </li>
                  ))}
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="ticket new-ticket">
        <div className="top">
          <div className="title">{translate('New Ticket')}</div>
          <hr />
        </div>

        {tickets?.length <= 100 ? <div className="actions">
          <div className="random">
            <button onClick={handleRandomCard}>
              <img src={randomAllIcon} alt="" />
              <span>{translate('Random')}</span>
            </button>
          </div>
          <div className="or">{translate('Or')}</div>
          <div className="pick-numbers">
            <button onClick={handleShowModal}>{translate('Pick Numbers')}</button>
          </div>
        </div>: null }
      </div>


      {showMobileModal && <div className={'game-modal'}>
        <div className="close" onClick={handleClose}>×</div>
        <div className={'dialog-header'}>
          {bonusView && <div className="back" onClick={handleBack}></div>}
          <div className="pick-title">
            {
              bonusView ? formatMessage({
                id: `Pick {number} bonus ${ticket?.bonusNumCount - ticket?.bonusNumbers.length === 1 ? 'Number': 'Numbers'}`
              }, {
                number: ticket?.bonusNumCount - ticket?.bonusNumbers.length
              }): formatMessage(
                {
                id: `Pick {number} ${ticket?.regNumCount - ticket?.mainNumbers.length === 1 ? 'Number': 'Numbers'}`
              }, {
                number: ticket?.regNumCount - ticket?.mainNumbers.length
              })
            }
          </div>

          <div className="picked-numbers numbers">
            <ul>
              {!ticket?.mainNumbers.length ? [1,2,3,4,5,6].map((element) => {
                return (<li className={'empty'} key={element}></li>)
              }): null }

              {ticket?.mainNumbers.map((element) => {
                return (<li className={'number selected'} key={element}>{element}</li>)
              }) }

              {ticket?.bonusNumbers.map((element) => {
                return (<li className={'number selected bonus'} key={element}>{element}</li>)
              })}
            </ul>
          </div>
        </div>
        <div className="dialog-content">
          {!bonusView && <div className="numbers">
            <ul>
              {ticket?.numbers.map((element) => {
                return (
                  <li
                    onClick={() => handleMobilePicked(element)}
                    className={classNames({
                    selected: ticket?.mainNumbers.includes(element)
                  })}>
                    {element}
                  </li>
                )
              })}
            </ul>
          </div>}
         {bonusView && <div className="numbers">
           <ul>
             {ticket?.bonuses.map((element) => {
               return (
                 <li
                   onClick={() => handleMobilePicked(element)}
                   className={classNames({
                   bonus: ticket?.bonusNumbers.includes(element)
                 })}>
                   {element}
                 </li>
               )
             })}
           </ul>
         </div>}
          <div className="proceed-btn clear">
            <button
              onClick={handleProceed}
              type="button" className={classNames('green-btn', {
              'disabled': (bonusView ? ticket?.bonusNumCount !== ticket?.bonusNumbers.length : ticket?.regNumCount !== ticket?.mainNumbers.length)
            })}>
              {translate('Proceed')}
            </button>
          </div>
        </div>
      </div>}
    </div>
  );
};

export default injectIntl(TicketComponentMobile);
