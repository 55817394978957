import React, { useContext, useState, useEffect } from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import { Helmet } from 'react-helmet';
import { Router, useParams, navigate } from '@reach/router';
import classNames from 'classnames';
import map from 'lodash/map';
import GameTimer from '../../components/GameTimer';
import { transform } from '../../utils/dateFormatted';
import randomAllIcon from '../../assets/icons/random-all-icon.svg';
import closeWhite from '../../assets/icons/close-white-icon.svg';
import './play.scss';
import TicketsComponent from '../../components/TicketsComponent';
import LotteriesContext from '../../state/LotteriesContext';
import { transformPrice } from '../../utils/formattes';
import ApplicationContext from '../../state/ApplicationContext';
import { randomNumbers, newTicket } from '../../utils/ticketsUtils';
import CartContext from '../../state/CartContext';


const costLine = '{price} / line';
const costMonth = '{price} / month';

const durationText = [
  {
    id: 1,
    label: '1 Draw',
    description: 'Just 1 draw. Remember to play again next draw.',
    period: costLine,
  },
  {
    id: 2,
    label: '2 Draws',
    description: 'Just 2 draws. Remember to play again in 2 weeks.',
    period: costLine,
  },
  {
    id: 8,
    label: '1 Month',
    description: 'A recurring subscription with 1 month of binding period.',
    period: costMonth,
  },
  {
    id: 24,
    label: '3 Months',
    description: 'A recurring subscription with a 3 month binding period.',
    period: costMonth,
  },
];

const GamePageWrapper = injectIntl(({ intl: { formatMessage, locale } }) => {
  const { lotteries } = useContext(LotteriesContext);
  const { isDesktop, setShowSignIn, user, handleGrowl } = useContext(ApplicationContext);
  const [tickets, setTickets] = useState([null, null, null, null]);
  const [durations, setDurations] = useState([]);
  const [checkedDuration, setCheckedDuration] = useState(1);
  const [currentGame, setCurrentGame] = useState(null);
  const [slug, setSlug] = useState('');
  const params = useParams();
  const translate = (str) => (formatMessage({id: str}))
  const {
    updateCartItems,
  } = useContext(CartContext);

  useEffect(() => {
    if(tickets[0]){
      localStorage.setItem('unsavedTickets', JSON.stringify(tickets))
    }
  }, [tickets])

  useEffect(() => {
    if (slug !== params.gameSlug) {
      setSlug(params.gameSlug);
      setTickets([null, null, null, null]);
      setDurations([]);
      setCheckedDuration(1);
      setCurrentGame(null);
    }
  }, [slug, params.gameSlug]);

  const currentLocale = locale === 'en' ? '': `/${locale}`
  const isDisabled = !tickets[0];
  useEffect(() => {
    const newCurrentGame = lotteries?.find((element) => element.slug === params.gameSlug);
    if (newCurrentGame) {
      setCurrentGame(newCurrentGame);
    }
    if(!newCurrentGame && lotteries?.length){
      navigate(currentLocale + "/404");
      return;
    }
    if (currentGame) {
      const newDurations = map(currentGame.prices, (price: any) => {
        price = {
          ...price,
          durationPrice: price.pricePerLine * (price.quantity >= 8 ? 8 : 1),
          content: durationText.find((element) => element.id === price.quantity),
        };
        return price;
      });
      setDurations(newDurations);
    }

    if (!isDesktop && !tickets.length) {
      setTickets([]);
    }

    if (currentGame && isDisabled) {
      const tickets = [];
      const cartItems = localStorage.getItem('unsavedTickets');
      if (cartItems) {
        const parsedCartItems = JSON.parse(cartItems);
        const currentGameCard = parsedCartItems.filter((element) =>{
          console.log(element);
          return (element && element.idGame === currentGame.id);
        });
        console.log(currentGameCard, currentGame.id)
        currentGameCard.forEach((element) => {
          tickets.push(element);
        });
      }
      const ticketsLength = 4 - tickets.length;
      for (let i = 0; i < ticketsLength; i++) {
        tickets.push(newTicket(currentGame));
      }
      setTickets(tickets);
    }
  }, [lotteries, params.gameSlug, tickets.length, isDesktop, currentGame, isDisabled]);

  const handleAddLines = () => {
    const currentGame = lotteries?.find((element) => element.slug === params.gameSlug);
    const newTickets = [...tickets];
    for (let i = 0; i < 4; i++) {
      newTickets.push(newTicket(currentGame));
    }
    setTickets(newTickets);
  };
  const handlePickNumber = (id, arr, isBonusNumbers) => {
    setTickets(tickets.map((element) => {
      if (element.id === id) {
        return ({
          ...element,
          mainNumbers: isBonusNumbers ? element.mainNumbers : arr,
          bonusNumbers: isBonusNumbers ? arr : element.bonusNumbers,
        });
      }
      return element;
    }));
  };
  const handleTicketCancel = (id) => {
    setTickets(tickets.map((element) => {
      if (element.id === id) {
        return ({
          ...element,
          mainNumbers: [],
          bonusNumbers: [],
        });
      }
      return element;
    }));
  };
  const handleTicketRandom = (id) => {
    setTickets(tickets.map((element) => {
      if (element.id === id) {
        return ({
          ...element,
          mainNumbers: randomNumbers(element.regNumCount, element.maxNum),
          bonusNumbers: randomNumbers(element.bonusNumCount, element.maxBonus),
        });
      }
      return element;
    }));
  };
  const handleRandomAll = () => {
    setTickets(tickets.map((element) => ({
      ...element,
      mainNumbers: randomNumbers(element.regNumCount, element.maxNum),
      bonusNumbers: randomNumbers(element.bonusNumCount, element.maxBonus),
    })));
  };
  const handleCancelAll = () => {
    setTickets(tickets.map((element) => ({
      ...element,
      mainNumbers: [],
      bonusNumbers: [],
    })));
  };
  const getTotalPrice = () => {
    if (!tickets[0]) return 0;
    const filteredPrice = tickets.filter((element) => element.mainNumbers.length === element.regNumCount && element.bonusNumbers.length === element.bonusNumCount);
    const selectedDuration = durations.find((element) => element.content.id === checkedDuration);
    if (!selectedDuration) return 0;
    const computedPrice = (selectedDuration.durationPrice * filteredPrice.length) * (selectedDuration.content.id >= 8 ? selectedDuration.content.id / 8 : selectedDuration.content.id);
    const price = transformPrice(computedPrice, 'forCart');

    const priceWithCurrency = transformPrice(computedPrice, 'priceWithCurrency');
    let priceInDuration = selectedDuration.content.id >= 8 ? formatMessage(
      { id: selectedDuration.content.period }, {
        price,
      },
    ) : price;

    if (!isDesktop || price === '€0.00') {
      if(selectedDuration.content.id < 8){
        priceInDuration = price;
      }
    }
    return {
      message: priceInDuration,
      isValid: Boolean(filteredPrice.length),
      filteredArray: filteredPrice,
      price,
      priceWithCurrency,
    };
  };
  const totalPrice = getTotalPrice();

  const addNewTicket = (ticket) => {
    const currrentTicket = tickets.find((element) => element.id === ticket.id);
    if (currrentTicket) {
      setTickets(tickets.map((element) => {
        if (element.id === ticket.id) return ticket;
        return element;
      }));
      return;
    }
    setTickets([...tickets, ticket]);
  };

  const handleSubmit = () => {
    if (!user) {
      setShowSignIn(true);
      return;
    }
    localStorage.removeItem('unsavedTickets')
    const cartItems = localStorage.getItem('cartItems');
    const selectedDuration = durations.find((element) => element.content.id === checkedDuration);
    const filteredArray = totalPrice.filteredArray.map((element) => ({
      ...element,
      duration: selectedDuration.content.id,
    }));
    if (cartItems) {
      const parsedCart = JSON.parse(cartItems);
      localStorage.setItem('cartItems', JSON.stringify([...parsedCart, ...filteredArray]));
    }

    if (!cartItems) {
      localStorage.setItem('cartItems', JSON.stringify(filteredArray));
    }
    updateCartItems();
    navigate(currentLocale + '/cart');
  };

  const getTranslatedDate = (date) => {
    const splitterDate = date.split(' ');
    const Month = splitterDate[2];
    const Day = splitterDate[0];

    return translate(Day) + ` ${splitterDate[1]} ` + translate(Month)
  };

  const disabledClearAll: boolean = Boolean(tickets.find((element) => (element && !!element.mainNumbers.length || element && !!element.bonusNumbers.length)))
  return (
    <div className="game-wrapper">
      <Helmet>
        {currentGame?.lotteryTitle && (
        <title>
          {`Lotteroo | ${translate('Play')} ${currentGame?.lotteryTitle}`}
        </title>
)}
      </Helmet>
      <div className="game-top-block">
        <div className="row">
          <div className="logo">
            <img
              src={currentGame ? currentGame.logoImgUrl : null}
              alt=""
            />
          </div>
          <div className="amount">
            {currentGame ? transformPrice(currentGame.jackpot, 'amountWithSpaces') : <span />}
            {Boolean(!currentGame) && (
            <span
              className="placeholder-data"
              style={{
                width: '100%',
                height: 50,
              }}
            />
            )}
          </div>
        </div>

        <GameTimer time={currentGame?.nextDrawTime} />
        <div className="next-draw-row page-content">
          <div className="next-draw">
            <div className="title">{translate('Next Draw')}</div>
            <div className="date">{currentGame && getTranslatedDate(transform(currentGame.nextDrawTime, 'dayWithMonth'))}</div>
            <div className="close-at">
              {translate('Sales close at')}
              {' '}
              {currentGame && transform(currentGame.nextDrawTime, 'time')}
            </div>
          </div>

          <div className="global-buttons">
            <div className="random-all">
              <button
                onClick={handleRandomAll}
                className={classNames({
                  disabled: isDisabled,
                })}
              >
                <img src={randomAllIcon} alt="" />
                <span> {translate('Random all')}</span>
              </button>
            </div>
            <div className="clear-all">
              <button
                onClick={handleCancelAll}
                className={classNames({
                  disabled: isDisabled || !disabledClearAll,
                })}
              >
                <img src={closeWhite} alt="" />
                <span>{translate('Clear all')}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="game-content">
          <TicketsComponent
            tickets={tickets}
            handleAddLines={handleAddLines}
            handleTicketCancel={handleTicketCancel}
            handleTicketRandom={handleTicketRandom}
            handlePickNumber={handlePickNumber}
            isDisabled={isDisabled}
            currentGame={currentGame}
            filteredTickets={totalPrice.filteredArray}
            addNewTicket={addNewTicket}
            translate={translate}
          />

          <div className={'duration-wrapper'}>
            <div className="duration">
              <div className="title">{translate('Duration')}</div>
              <ul>
                {Boolean(durations.length) && durations.map((element) => (
                  <li className={classNames({
                    selected: false,
                  })}
                  >
                    <div className="draw-wrap">
                      <div className="input-wrap">
                        <input
                          type="radio"
                          className="custom-input"
                          name="opt"
                          checked={checkedDuration === element.quantity}
                          onChange={() => setCheckedDuration(element.quantity)}
                          id={element.quantity}
                          value={element.quantity}
                        />
                        <label htmlFor={element.quantity} />
                      </div>
                      <div className="option-wrap">
                        <label htmlFor={element.quantity} className="option">
                          <h3>{translate(element.content?.label)}</h3>
                          <div className="description-wrap">
                            <div className="description">{translate(element.content?.description)}</div>
                          </div>
                        </label>
                        <div className="price">
                          {element.content.period ? formatMessage(
                            { id: element.content.period }, {
                              price: transformPrice(element.durationPrice, 'priceWithCurrency'),
                            },
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}

                {Boolean(!durations.length) && [1, 2, 3, 4].map((element) => (
                  <li
                    className="placeholder-data"
                    style={{
                      width: '100%',
                      height: 50,
                    }}
                  />
                ))}
              </ul>
              <div className="bottom">
                <div className="total">
                  <div className="label">{translate('Total price:')}</div>
                  <div className="price">{totalPrice.message}</div>
                </div>
                <div className="submit">
                  <button
                    disabled={!totalPrice.isValid}
                    type="button"
                    onClick={handleSubmit}
                    className={classNames('submit-btn', {
                      disabled: !totalPrice.isValid,
                    })}
                  >
                    {translate('Submit for')}
                    {' '}
                    {totalPrice.priceWithCurrency}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const Play = () => (
  <Router>
    <GamePageWrapper path=":lang/play" />
    <GamePageWrapper path="/play" />
    <GamePageWrapper path="/play/:gameSlug" />
    <GamePageWrapper path="/:lang/play/:gameSlug" />
  </Router>
);

export default Play;
